<template>
  <div>
    <a-row>
      <a-col>
        <RightOne></RightOne>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <right-two-line></right-two-line>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <RightThreeList></RightThreeList>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import RightTwoLine from './rightTwo'
  import RightThreeList from './rightThree'
  import RightOne from './rightOne'

  export default {
    name: 'right',
    components: { RightOne, RightThreeList, RightTwoLine }
  }
</script>

<style scoped>

</style>