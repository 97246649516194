<template>
  <div class="view-box" style="height: 800px;">
    <!--<div class="view-box-title">报抽排行</div>-->
    <div class="view-box-title">
      <a-row>
        <a-col :span="6">
          清运任务监控
        </a-col>
        <a-col :span="18">
          <a-row>
            <a-col :span="24" style="cursor: pointer;text-align: right;">
              <a-radio-group default-value="a" @change="onchange" button-style="solid">
                <a-radio-button value="a">
                  待清运
                </a-radio-button>
                <a-radio-button value="b">
                  清运中
                </a-radio-button>
                <a-radio-button value="c">
                  已清运
                </a-radio-button>
                <a-radio-button value="e">
                  清运预测
                </a-radio-button>
                <a-radio-button value="d">
                  报抽排行
                </a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="view-box-content" id="scroll-board" style="padding: 0px">
      <dv-scroll-board :config="config" style="height: 435px" hoverPause indexHeader="排名"/>
    </div>
  </div>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'LeftThree',
    data () {
      return {
        config: {},
        config1: {},
        config2: {},
        config3: {},
        config4: {},
        config5: {},
        bindStatus: 'unClean'
      }
    },
    created () {
      this.getReportRecordData()
    },
    methods: {
      onchange (e) {
        if (e.target.value === 'a') {
          this.bindStatus = 'unClean'
          this.getReportRecordData()
        } else if (e.target.value === 'b') {
          this.bindStatus = 'DOING'
          this.getReportRecordData()
        } else if (e.target.value === 'c') {
          this.bindStatus = 'COMPLETE'
          this.getReportRecordData()
        } else if (e.target.value === 'd') {
          this.getReportRankData()
        } else if (e.target.value === 'e') {
          this.findLoopForecastList()
        }
      },
      getReportRankData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getReportRankDataByCodes,
          noTips: true,
          loading: 'no',
          data: {
            codes: '04'
          },
          success: (data) => {
            this.config = {
              header: ['排名', '集污点名称', '集污点类型', '容积/m³', '上报次数'],
              data: data.body,
              index: false,
              columnWidth: [80, 200, 120, 100, 100],
              align: ['center'],
              headerHeight: 35,
              rowNum: 10,
              carousel: 'page',
              headerBGC: 'rgba(0, 44, 81, 0.8)',
              oddRowBGC: 'rgba(0, 44, 81, 0.8)',
              evenRowBGC: 'rgba(10, 29, 50, 0.8)'
            }
          },
          error: () => {
          }
        })
      },
      getReportRecordData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.noPageSearchListBYCodes,
          data: {
            bindStatus: this.bindStatus,
            codes: '04'
          },
          noTips: true,
          loading: 'no',
          success: (data) => {
            this.config = {
              header: ['集污点名称', '类型', '容积/m³', '上报时间', '上报电话', '清运司机'],
              data: data.body,
              index: false,
              columnWidth: [140, 130, 100, 130, 150, 100],
              align: ['center'],
              headerHeight: 35,
              rowNum: 10,
              carousel: 'page',
              headerBGC: 'rgba(0, 44, 81, 0.8)',
              oddRowBGC: 'rgba(0, 44, 81, 0.8)',
              evenRowBGC: 'rgba(10, 29, 50, 0.8)'
            }
          },
          error: () => {
          }
        })
      },
      findLoopForecastList () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.findLoopForecastListLimitByCodes,
          noTips: true,
          loading: 'no',
          data: {
            codes: '04'
          },
          success: (data) => {
            this.config = {
              header: ['集污点名称', '集污点类型', '容积/m³', '农户电话', '预计清运时间'],
              data: data.body,
              index: false,
              columnWidth: [140, 130, 100, 130, 150],
              align: ['center'],
              headerHeight: 35,
              rowNum: 10,
              carousel: 'page',
              headerBGC: 'rgba(0, 44, 81, 0.8)',
              oddRowBGC: 'rgba(0, 44, 81, 0.8)',
              evenRowBGC: 'rgba(10, 29, 50, 0.8)'
            }
          },
          error: () => {
          }
        })
      }
    }
  }
</script>