<template>
  <div class="view-box">
    <div class="view-box-title">KPI指标统计</div>
    <div><!-- class="view-content-box"-->
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card" style="margin-left: 0px;">
        <div class="content">
          <span class="card-title">报抽总数</span><br>
          <span class="card-value">{{kpiData.allCount}}<span class="unit"> 次</span></span><br>
        </div>
      </dv-border-box-7>
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
        <div class="content">
          <span class="card-title">已清运数量</span><br>
          <span class="card-value">{{kpiData.processedCount + kpiData.feedbackCompleteCount}}<span class="unit"> 次</span></span><br>
        </div>
      </dv-border-box-7>
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
        <div class="content">
          <span class="card-title">清运中数量</span><br>
          <span class="card-value">{{kpiData.processingCount||0}}<span class="unit"> 次</span></span><br>
        </div>
      </dv-border-box-7>
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
        <div class="content">
          <span class="card-title">未清运数量</span><br>
          <span class="card-value">{{kpiData.scheduledCount||0}}<span class="unit"> 次</span></span><br>
        </div>
      </dv-border-box-7>
    </div>
  </div>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'LeftOne',
    data () {
      return {
        kpiData: {}
      }
    },
    created () {
      this.getKPIData()
    },
    methods: {
      getKPIData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getKpiReportData2,
          noTips: true,
          loading: 'no',
          data: {
            areaNo: ['04']
          },
          success: (data) => {
            this.kpiData = data.body
          },
          error: () => {
          }
        })
      }
    }
  }
</script>
<style scoped>
</style>