<template>
  <div id="data-view">
    <dv-full-screen-container>
      <Header></Header>
      <div class="content">
        <a-row style="padding: 10px;">
          <a-col :span="8">
          <left></left>
          </a-col>
          <a-col :span="8" style="text-align: center">
            <a-row class="center-map">
              <center-map></center-map>
            </a-row>
            <a-row style="z-index: 100;position: relative;bottom: 50px;">
              <a-col>
              <center-two></center-two>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <right></right>
          </a-col>
        </a-row>
      </div>
    </dv-full-screen-container>
  </div>
</template>
<script>
  import Header from './Header'
  import CenterMap from './CenterMap'
  import Right from './right'
  import Left from './left'
  import CenterTwo from './centerTwo'

  export default {
    components: { CenterTwo, Left, Right, Header, CenterMap }
  }
</script>
<style lang="less">
  #data-view {
    width: 100%;
    color: #a2d3ff;
    height: 100%;
    background-color: #154f9e;

    #dv-full-screen-container {
      background-image: url('../screen-home/img/bg.png');
      background-size: 100% 100%;
      box-shadow: 0 0 3px blue;
      display: flex;
      flex-direction: column;
    }
  }

  .date-count {
    font-weight: bolder;
    font-size: larger;
    color: white;
  }

  .view-box {
    margin: 10px;

    .view-box-title {
      font-size: 20px;
      font-weight: bold;
      color: #7fd8ec;
    }

    .view-box-content {
      padding: 8px;
      margin-top: 10px;
      border: 2px solid #4e7bce;
      border-radius: 6px;
    }
  }

  .center-map {
    margin: 14px;
    border: 0px solid #123473;
    border-radius: 8px;
    overflow: hidden;
    width: 606px;
    height: 766px;
  }
  .l7-control-logo {
    display: none;
  }
  .data-card {
    width: 24%;
    height: auto;
    float: left;
    margin-left: 1.33%;
    text-align: center;

    .content {
      padding: 15px;

      .card-title {
        font-size: 14px;
        font-weight: bold;
        color: #16bbc3;
      }

      .card-value {
        font-size: 25px;
        font-weight: bold;
        line-height: 36px;

        .unit {
          font-weight: normal;
          font-size: 12px;
        }
      }

      .card-footer {
        font-weight: bold;
        font-size: 12px;
        color: #3a97a0;
      }
    }
  }

</style>