<template>
  <div class="content m-t-24">
    <a-row>
      <a-col>
        <LeftOne></LeftOne>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <LeftTwo></LeftTwo>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <LeftThree></LeftThree>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import LeftTwo from './LeftTwo'
  import LeftOne from './LeftOne'
  import LeftThree from './LeftThree'
  export default {
    name: 'left',
    components: { LeftThree, LeftOne, LeftTwo },
    data () {
      return {
      }
    }
  }
</script>

<style lang="less">
  #data-view {
    width: 100%;
    color: #a2d3ff;
    height: 100%;
    background-color: #154f9e;

    #dv-full-screen-container {
      background-image: url('../screen-home/img/bg.png');
      background-size: 100% 100%;
      box-shadow: 0 0 3px blue;
      display: flex;
      flex-direction: column;
    }
  }

  .date-count {
    font-weight: bolder;
    font-size: larger;
    color: white;
  }

  .view-box {

    .view-box-title {
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #fbfbfb;
    }

    .view-box-content {
      margin-top: 10px;
      border: 1px solid #00e3ff;
      border-radius: 6px;
    }
  }

  .center-map {
    margin: 14px;
    border: 0px solid #123473;
    border-radius: 8px;
    overflow: hidden;
    width: 606px;
    height: 766px;
  }

  .l7-control-logo {
    display: none;
  }

  .data-card {
    width: 24%;
    height: auto;
    float: left;
    margin-left: 1.23%;
    text-align: center;

    .content {
      padding: 15px;

      .card-title {
        font-size: 13px;
        font-weight: bold;
        color: #16bbc3;
      }

      .card-value {
        font-size: 20px;
        font-weight: bold;
        line-height: 40px;

        .unit {
          font-weight: normal;
          font-size: 12px;
        }
      }

      .card-footer {
        font-weight: bold;
        font-size: 12px;
        color: #3a97a0;
      }
    }
  }

  .view-content-box {
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .margin-top {
    margin-top: 40px
  }

  .radio-col {
    cursor: pointer;
    text-align: right;
    padding-right: 10px
  }

  .ant-radio-button-wrapper {
    height: 27px;
    line-height: 26px;
    padding: 0 10px;
    font-size: 10px
  }

  .content-col {
    border: 1px solid #00e3ff;
    border-radius: 5px;
    margin-top: 20px;
    width: 610px;
    margin-left: 10px
  }

  .col-height {
    height: 280px;
  }

  #scroll-board {
    height: 440px;
    overflow: hidden;
  }

  .layout {
    .ant-layout {
      .header {
        height: auto;
      }
    }
  }
</style>
